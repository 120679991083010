// Base64 Images for display purposes
const heroImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAJYAQMAAACuCNNxAAAAA1BMVEVdXV3Z0Y5pAAAAi0lEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOD24EAAAAAAQJC/9SBXAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwEyoRwABkkbxHQAAAABJRU5ErkJggg==';
const heroImageMobile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAAGQAQMAAADlYCvGAAAAA1BMVEVdXV3Z0Y5pAAAAJ0lEQVR42u3BMQEAAADCIPuntsYOYAAAAAAAAAAAAAAAAAAAAEDOAUAQAAEsqk39AAAAAElFTkSuQmCC';
const bannerImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAG4AQMAAACJpNJuAAAAA1BMVEVdXV3Z0Y5pAAAAcElEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOD24IAEAAAAQND/1+0IVAAAAAAAAAAAAAAAAAAAAAAAYCY3JwABtz+iFwAAAABJRU5ErkJggg==';
const bannerImageMobile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAAFeAQMAAADOglQCAAAAA1BMVEVdXV3Z0Y5pAAAAJUlEQVRo3u3BMQEAAADCIPuntsYOYAAAAAAAAAAAAAAAAABA3AE4DgABRhPjpwAAAABJRU5ErkJggg==';
const bannerVideo = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAoAAAAFoAQMAAAD9/NgSAAAAA1BMVEW1tbVGVhzfAAAAMklEQVR42u3BAQ0AAADCIPundnsOYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADkcegAAefG1GoAAAAASUVORK5CYII=';
const videoSectionMobile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAAHkAQMAAAB102QRAAAAA1BMVEVdXV3Z0Y5pAAAAKUlEQVR42u3BAQEAAACCoP6vbojAAAAAAAAAAAAAAAAAAAAAAAAAAEIOTYQAAbA8/woAAAAASUVORK5CYII=';
const twoUpImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAmIAAAH0AQMAAACkT9NmAAAAA1BMVEVdXV3Z0Y5pAAAAPElEQVR42u3BgQAAAADDoPtTX2EA1QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACA7JhYAAH87I4RAAAAAElFTkSuQmCC';
const twoUpImageMobile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAAFoAQMAAAAcobCyAAAAA1BMVEVdXV3Z0Y5pAAAAJUlEQVRo3u3BMQEAAADCIPuntsNuYAAAAAAAAAAAAAAAAAAAJAc5qAAByC5E/gAAAABJRU5ErkJggg==';
const twoUpLongImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAmIAAAMCAQMAAADwGzhLAAAAA1BMVEVdXV3Z0Y5pAAAAUElEQVR42u3BAQ0AAADCIPuntscHDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACDk6pwAASGxpVIAAAAASUVORK5CYII=';
const twoUpLongImageMobile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV4AAAGUAQMAAABHltiLAAAAA1BMVEVdXV3Z0Y5pAAAAKElEQVR42u3BMQEAAADCIPunNsYeYAAAAAAAAAAAAAAAAAAAAAAAEDlHBAABYTtXzwAAAABJRU5ErkJggg==';
const threeUpImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXwAAAGQAQMAAACXr0lwAAAAA1BMVEVdXV3Z0Y5pAAAAKUlEQVR42u3BAQEAAACCoP6vbojAAAAAAAAAAAAAAAAAAAAAAAAAgKwDTJAAAU9nx3IAAAAASUVORK5CYII=';
const threeUpImageMobile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsAQMAAABDsxw2AAAAA1BMVEVdXV3Z0Y5pAAAAIklEQVRo3u3BMQEAAADCIPunNsU+YAAAAAAAAAAAAAAAZA4ttAABTfPAzgAAAABJRU5ErkJggg==';
const threeUpLongImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYoAAAMCAQMAAABNzBPPAAAAA1BMVEVdXV3Z0Y5pAAAAPUlEQVR42u3BMQEAAADCIPuntsNuYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAyQGZZgAB1A3MZwAAAABJRU5ErkJggg==';
const threeUpLongImageMobile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM0AAAGQAQMAAAA3FXa3AAAAA1BMVEVdXV3Z0Y5pAAAAIUlEQVRo3u3BAQ0AAADCIPuntscHDAAAAAAAAAAAAIC0AyowAAGJmq1+AAAAAElFTkSuQmCC';
const thirdSplitCard = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAABkAQMAAABelVuzAAAAA1BMVEW1tbVGVhzfAAAAFklEQVQ4y2MYBaNgFIyCUTAKRsGQBwAH0AABC3LNGAAAAABJRU5ErkJggg==';
const thirdSplit = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAG4AQMAAACJpNJuAAAAA1BMVEVdXV3Z0Y5pAAAAcElEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOD24IAEAAAAQND/1+0IVAAAAAAAAAAAAAAAAAAAAAAAYCY3JwABtz+iFwAAAABJRU5ErkJggg==';
const thirdSplitMobile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUAAAAHFAQMAAAC5I7KCAAAAA1BMVEVdXV3Z0Y5pAAAAKElEQVR42u3BAQEAAACCoP6vbojAAAAAAAAAAAAAAAAAAAAAAACAtgNIjQABxSPPRgAAAABJRU5ErkJggg==';

function escapeHtml(html) {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        '/': '&#x2F;',
    };
    return String(html).replace(/[&<>"'/]/g, (s) => entityMap[s]);
}

function showCode() {
    $('.content-output pre code').empty();
    $('.content-output pre code').append($('.built-content').contents().clone());

    const codeblock = document.querySelectorAll('pre code');

    if (codeblock.length > 0) {
        for (let i = 0, len = codeblock.length; i < len; i += 1) {
            codeblock[i].innerHTML = escapeHtml(codeblock[i].innerHTML);
        }
    }
}

function getWidth(value) {
    let widthVar;
    switch (value) {
    case '1':
        widthVar = '';
        break;
    case '2':
        widthVar = 'width-full';
        break;
    case '3':
        widthVar = 'width-third';
        break;
    case '4':
        widthVar = 'width-half';
        break;
    case '5':
        widthVar = 'width-twothird';
        break;
    default:
        widthVar = '';
        break;
    }
    return widthVar;
}

function getPosition(value) {
    let positionVar;
    switch (value) {
    case '1':
        positionVar = 'align-top-left-pos';
        break;
    case '2':
        positionVar = 'align-top-center-pos';
        break;
    case '3':
        positionVar = 'align-top-right-pos';
        break;
    case '4':
        positionVar = 'align-middle-left-pos';
        break;
    case '5':
        positionVar = 'align-middle-center';
        break;
    case '6':
        positionVar = 'align-middle-center-pos';
        break;
    case '7':
        positionVar = 'align-middle-right-pos';
        break;
    case '8':
        positionVar = 'align-bottom-left-pos';
        break;
    case '9':
        positionVar = 'align-bottom-center-pos';
        break;
    case '10':
        positionVar = 'align-bottom-right-pos';
        break;
    default:
        positionVar = '';
        break;
    }
    return positionVar;
}

function getBackground(value) {
    let backgroundVar;
    if (value) {
        backgroundVar = 'drop-background';
    } else {
        backgroundVar = '';
    }
    return backgroundVar;
}

function getHeaderStyle(value) {
    let headStyle;
    switch (value) {
    case '1':
        headStyle = '';
        break;
    case '2':
        headStyle = 'primary';
        break;
    case '3':
        headStyle = 'inverse';
        break;
    default:
        headStyle = '';
        break;
    }
    return headStyle;
}

function getSubHeaderStyle(value) {
    let subHeadStyle;
    switch (value) {
    case '1':
        subHeadStyle = '';
        break;
    case '2':
        subHeadStyle = 'primary';
        break;
    case '3':
        subHeadStyle = 'inverse';
        break;
    default:
        subHeadStyle = '';
        break;
    }
    return subHeadStyle;
}

function getCtaStyle(value) {
    let ctaStyle;
    switch (value) {
    case '1':
        ctaStyle = 'primary';
        break;
    case '2':
        ctaStyle = 'secondary';
        break;
    case '3':
        ctaStyle = 'tertiary';
        break;
    default:
        ctaStyle = '';
        break;
    }
    return ctaStyle;
}

function getTileType(value) {
    let tileTypeValue;
    switch (value) {
    case '3':
        tileTypeValue = 'two-up';
        break;
    case '4':
        tileTypeValue = 'three-up';
        break;
    default:
        tileTypeValue = '';
        break;
    }
    return tileTypeValue;
}

function getTileCount(value) {
    let tileCountValue;
    switch (value) {
    case '3':
        tileCountValue = 2;
        break;
    case '4':
        tileCountValue = 3;
        break;
    default:
        tileCountValue = '';
        break;
    }
    return tileCountValue;
}

function getSideBySidePos(value) {
    let sbspos;
    switch (value) {
    case '1':
        sbspos = 'first';
        break;
    case '2':
        sbspos = 'second';
        break;
    default:
        sbspos = '';
        break;
    }
    return sbspos;
}

function buildHero(contentParams) {
    const cWidth = getWidth(contentParams.width);
    const cPosition = getPosition(contentParams.position);
    const cBackground = getBackground(contentParams.background);
    const cHeaderStyle = getHeaderStyle(contentParams.headerStyle);
    const cSubHeaderStyle = getSubHeaderStyle(contentParams.subHeaderStyle);
    const cCtaStyle = getCtaStyle(contentParams.ctaStyle);
    let cHeroSecondary = '';
    if (contentParams.heroSecondary) {
        cHeroSecondary = 'secondary-hero';
    }
    let cMobilePrimary = '';
    if (contentParams.mobilePrimary) {
        cMobilePrimary = 'mobile-primary';
    }

    $('.built-content').empty();

    let htmlButtons = '';
    for (let i = 0; i < contentParams.ctaCount; i += 1) {
        if (i === 0) {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[0]}</a>`;
        } else if (i === 1) {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[1]}</a>`;
        } else {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[2]}</a>`;
        }
    }

    const backgroundStyle = contentParams.background ? ' style="background-color:rgba(250, 248, 241, 0.94);"' : '';
    const html = $([
        '<!-- Home slot 1 -->',
        `<div class="hero-container ${cHeroSecondary}">`,
        '    <picture>',
        '        <!-- desktop image -->',
        '        <!-- //For SFCC <source media="(min-width: 1025px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
        `        <source media="(min-width: 1025px)" srcset="${heroImage}">`,
        '        <!-- tablet image -->',
        '        <!-- // For SFCC <source media="(min-width: 768px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
        `        <source media="(min-width: 768px)" srcset="${heroImage}">`,
        '        <!-- default is mobile image -->',
        '        <!-- // For SFCC <img alt="" src="images/content-assets/homepage/cd-hero1-m.png?$staticlink$"/>-->',
        `        <img alt="" src="${heroImageMobile}"/>`,
        '    </picture>',
        '    <div class="hero-content">',
        `        <div class="hero-content-inner ${cWidth} ${cPosition} ${cBackground} ${cMobilePrimary}" ${backgroundStyle}>`,
        `            <span class="type-hero ${cHeaderStyle}">${contentParams.header[0]}</span>`,
        `            <h3 class="${cSubHeaderStyle}">${contentParams.subHeader}</h3>`,
        '            <div class="cta-container">',
        `                ${htmlButtons}`,
        '            </div>',
        '        </div>',
        '    </div>',
        '</div>',
    ].join('\n'));

    $('.built-content').append(html);
}

function buildBanner(contentParams) {
    const cWidth = getWidth(contentParams.width);
    const cPosition = getPosition(contentParams.position);
    const cBackground = getBackground(contentParams.background);
    const cHeaderStyle = getHeaderStyle(contentParams.headerStyle);
    const cSubHeaderStyle = getSubHeaderStyle(contentParams.subHeaderStyle);
    const cCtaStyle = getCtaStyle(contentParams.ctaStyle);
    let cBannerAlternate = '';
    if (contentParams.bannerAlternate) {
        cBannerAlternate = 'alternate';
    }
    let cBannerStacked = '';
    if (contentParams.bannerStacked) {
        cBannerStacked = 'stacked-banner';
    }
    let cBannerSideBySide = '';
    if (contentParams.bannerSideBySide) {
        cBannerSideBySide = 'side-by-side';
    }
    const cSideBySidePosition = getSideBySidePos(contentParams.sideBySidePosition);
    let cPos1 = '';
    if (cSideBySidePosition === 'first') {
        cPos1 = 'second';
    } else {
        cPos1 = 'first';
    }
    let cMobilePrimary = '';
    if (contentParams.mobilePrimary) {
        cMobilePrimary = 'mobile-primary';
    }

    let htmlButtons = '';
    let html;
    let contentInner;
    let contentInnerHeader;
    let contentInnerSubHead;
    let contentSecondary;
    let contentSecondaryCallout;

    for (let i = 0; i < contentParams.ctaCount; i += 1) {
        if (i === 0) {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[0]}</a>`;
        } else if (i === 1) {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[1]}</a>`;
        } else {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[2]}</a>`;
        }
    }

    $('.built-content').empty();

    if (!contentParams.bannerSideBySide) {
        const backgroundStyle = contentParams.background ? ' style="background-color:rgba(250, 248, 241, 0.94);"' : '';
        contentInner = `<div class="hero-content-inner ${cWidth} ${cPosition} ${cBackground} ${cMobilePrimary}" ${backgroundStyle}>`;
        contentInnerHeader = `<span class="type-hero ${cHeaderStyle}">${contentParams.header[0]}</span>`;
        contentInnerSubHead = `<h3 class="${cSubHeaderStyle}">${contentParams.subHeader}</h3>`;

        html = $([
            '<!-- Banner slot -->',
            `<div class="hero-container banner ${cBannerAlternate} ${cBannerSideBySide}">`,
            '    <picture>',
            '        <!-- desktop image -->',
            '        <!-- //For SFCC <source media="(min-width: 1025px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
            `        <source media="(min-width: 1025px)" srcset="${bannerImage}">`,
            '        <!-- tablet image -->',
            '        <!-- // For SFCC <source media="(min-width: 768px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
            `        <source media="(min-width: 768px)" srcset="${bannerImage}">`,
            '        <!-- default is mobile image -->',
            '        <!-- // For SFCC <img alt="" src="images/content-assets/homepage/cd-hero1-m.png?$staticlink$"/>-->',
            `        <img alt="" src="${bannerImageMobile}"/>`,
            '    </picture>',
            '    <div class="hero-content">',
            `        ${contentInner}`,
            `            ${contentInnerHeader}`,
            `            ${contentInnerSubHead}`,
            '            <div class="cta-container">',
            `                ${htmlButtons}`,
            '            </div>',
            '        </div>',
            '    </div>',
            '</div>',
        ].join('\n'));
    } else if (!contentParams.bannerStacked) {
        // Call text content
        contentInner = `<div class="hero-content-inner call-text ${cSideBySidePosition}">`;
        contentInnerHeader = `<span class="type-hero ${cHeaderStyle}">${contentParams.header[0]}</span>`;
        contentInnerSubHead = `<p class="${cSubHeaderStyle}">${contentParams.subHeader}</p>`;
        // Video content
        contentSecondary = `<div class="hero-content-inner video ${cPos1}">`;
        contentSecondaryCallout = `<img alt="" src="${bannerVideo}"/>`;

        html = $([
            '<!-- Banner slot -->',
            `<div class="hero-container banner ${cBannerAlternate} ${cBannerSideBySide}">`,
            '    <picture>',
            '        <!-- desktop image -->',
            '        <!-- //For SFCC <source media="(min-width: 1025px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
            `        <source media="(min-width: 1025px)" srcset="${bannerImage}">`,
            '        <!-- tablet image -->',
            '        <!-- // For SFCC <source media="(min-width: 768px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
            `        <source media="(min-width: 768px)" srcset="${bannerImage}">`,
            '        <!-- default is mobile image -->',
            '        <!-- // For SFCC <img alt="" src="images/content-assets/homepage/cd-hero1-m.png?$staticlink$"/>-->',
            `        <img alt="" src="${videoSectionMobile}"/>`,
            '    </picture>',
            '    <div class="hero-content">',
            `        ${contentInner}`,
            `            ${contentInnerHeader}`,
            `            ${contentInnerSubHead}`,
            '            <div class="cta-container">',
            `                ${htmlButtons}`,
            '            </div>',
            '        </div>',
            `        ${contentSecondary}`,
            `            ${contentSecondaryCallout}`,
            '        </div>',
            '    </div>',
            '</div>',
        ].join('\n'));
    } else {
        contentInner = `<div class="inner-content ${cSideBySidePosition}">`;
        contentInnerHeader = `<h2 class="${cHeaderStyle}">${contentParams.header[0]}</h2>`;
        contentInnerSubHead = `<p class="${cSubHeaderStyle}">${contentParams.subHeader}</p>`;
        contentSecondary = `<div class="inner-content ${cPos1}">`;
        contentSecondaryCallout = `<img alt="" src="${thirdSplitCard}"/>`;

        html = $([
            '<!-- Banner slot -->',
            `<div class="hero-container banner ${cBannerAlternate} ${cBannerSideBySide}">`,
            '    <picture>',
            '        <!-- desktop image -->',
            '        <!-- //For SFCC <source media="(min-width: 1025px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
            `        <source media="(min-width: 1025px)" srcset="${bannerImage}">`,
            '        <!-- tablet image -->',
            '        <!-- // For SFCC <source media="(min-width: 768px)" srcset="images/content-assets/homepage/cd-hero1-m.png?$staticlink$">-->',
            `        <source media="(min-width: 768px)" srcset="${videoSectionMobile}">`,
            '        <!-- default is mobile image -->',
            '        <!-- // For SFCC <img alt="" src="images/content-assets/homepage/cd-hero1-m.png?$staticlink$"/>-->',
            `        <img alt="" src="${videoSectionMobile}"/>`,
            '    </picture>',
            `    <div class="hero-content ${cBannerStacked}">`,
            `        <div class="hero-content-inner mobile-content-inside-middle-center interior-button ${cWidth} ${cPosition}">`,
            `            ${contentInner}`,
            `                ${contentInnerHeader}`,
            `                ${contentInnerSubHead}`,
            '                <div class="cta-container">',
            `                    ${htmlButtons}`,
            '                </div>',
            '            </div>',
            `            ${contentSecondary}`,
            `                ${contentSecondaryCallout}`,
            '            </div>',
            '        </div>',
            '    </div>',
            '</div>',
        ].join('\n'));
    }

    $('.built-content').append(html);
}

function buildTiles(contentParams) {
    const cPosition = getPosition(contentParams.position);
    const cHeaderStyle = getHeaderStyle(contentParams.headerStyle);
    const cCtaStyle = getCtaStyle(contentParams.ctaStyle);
    const cTileType = getTileType(contentParams.tile.type);
    const cTileCount = getTileCount(contentParams.tile.type);
    let cTileContentLocation = '';
    if (contentParams.tile.contentLocation === '1') {
        cTileContentLocation = 'inner-content';
    } else {
        cTileContentLocation = 'outer-content';
    }
    let cTileAlternate = '';
    if (contentParams.tile.alternate) {
        cTileAlternate = 'alternate';
    }
    let cTileTreatment = '';
    if (contentParams.tile.treatment) {
        cTileTreatment = 'long-treatment';
    }
    let cMobilePrimary = '';
    if (contentParams.mobilePrimary) {
        cMobilePrimary = 'mobile-primary';
    }

    let tileImage;
    let tileImageMobile;
    const htmlBuild = [''];
    let html = [''];

    /// Determine if it's a two or three up
    if (contentParams.tile.type === '3') {
        /// Determine what image (long/normal) to use
        if (cTileTreatment === 'long-treatment') {
            tileImage = twoUpLongImage;
            tileImageMobile = twoUpLongImageMobile;
        } else {
            tileImage = twoUpImage;
            tileImageMobile = twoUpImageMobile;
        }
    } else if (cTileTreatment === 'long-treatment') {
        tileImage = threeUpLongImage;
        tileImageMobile = threeUpLongImageMobile;
    } else {
        tileImage = threeUpImage;
        tileImageMobile = threeUpImageMobile;
    }

    $('.built-content').empty();

    let tileButtonOne;
    let tileButtonTwo;
    let tilesHeader = contentParams.header[0];
    let tilesButton = contentParams.button[0];

    const tiles1 = [
        '<!-- Content Tiles -->',
        `<div class="content-tiles ${cTileType} ${cTileContentLocation} ${cTileAlternate} ${cTileTreatment}">`,
        `    <div class="content-tiles-heading ${cMobilePrimary}">`,
        `        <h2 class="${cHeaderStyle}">${contentParams.tile.calloutHeader}</h2>`,
        `        <p>${contentParams.tile.calloutSubHeader}</p>`,
        '    </div>',
        '    <div class="content-tiles-wrapper">',
    ];

    const tilesContainer = [
        '        <div class="content-tile-container">',
    ];

    const tilesContainerEnd = [
        '        </div>',
    ];

    const tiles2 = [
        '    </div>',
        '</div>',
    ];

    /// Start the tiles
    $.merge(htmlBuild, tiles1);
    for (let j = 0; j < cTileCount; j += 1) {
        /// Add the tile container
        $.merge(htmlBuild, tilesContainer);

        /// Determine if the tiles are inside/outside content
        if (contentParams.tile.contentLocation === '1') {
            let htmlButtons = '';
            for (let i = 0; i < contentParams.ctaCount; i += 1) {
                switch (j) {
                case 0:
                    [tileButtonOne, tileButtonTwo] = contentParams.button;
                    break;
                case 1:
                    [tileButtonOne, tileButtonTwo] = contentParams.tile.twoButton;
                    break;
                case 2:
                    [tileButtonOne, tileButtonTwo] = contentParams.tile.threeButton;
                    break;
                default:
                    break;
                }
                if (i === 0) {
                    htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${tileButtonOne}</a>`;
                } else {
                    htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${tileButtonTwo}</a>`;
                }
            }

            if (j === 0) {
                [tilesHeader] = contentParams.header;
            } else if (j === 1) {
                [, tilesHeader] = contentParams.header;
            } else {
                [, , tilesHeader] = contentParams.header;
            }
            const tilesInside = [
                '            <picture>',
                '                <!-- desktop image -->',
                '                <!-- //For SFCC <source media="(min-width: 1025px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
                `                <source media="(min-width: 1025px)" srcset="${tileImage}">`,
                '                <!-- tablet image -->',
                '                <!-- // For SFCC <source media="(min-width: 768px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
                `                <source media="(min-width: 768px)" srcset="${tileImage}">`,
                '                <!-- default is mobile image -->',
                '                <!-- // For SFCC <img alt="" src="images/content-assets/homepage/cd-hero1-m.png?$staticlink$"/>-->',
                `                <img alt="" src="${tileImageMobile}"/>`,
                '            </picture>',
                '            <div class="content-tile-inner-wrapper">',
                `                <div class="content-tile-inner width-full ${cPosition}">`,
                `                    <h2 class="${cHeaderStyle}">${tilesHeader}</h2>`,
                '                    <div class="cta-container">',
                `                        ${htmlButtons}`,
                '                    </div>',
                '                </div>',
                '            </div>',
            ];
            $.merge(htmlBuild, tilesInside);
        } else {
            if (j === 0) {
                [tilesButton] = contentParams.button;
            } else if (j === 1) {
                [, tilesButton] = contentParams.button;
            } else {
                [, , tilesButton] = contentParams.button;
            }
            const tilesOutside = [
                '            <a href="#">',
                '                <div class="content-tile-inner">',
                '                    <picture>',
                '                        <!-- desktop image -->',
                '                        <!-- //For SFCC <source media="(min-width: 1025px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
                `                        <source media="(min-width: 1025px)" srcset="${tileImage}">`,
                '                        <!-- tablet image -->',
                '                        <!-- // For SFCC <source media="(min-width: 768px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
                `                        <source media="(min-width: 768px)" srcset="${tileImage}">`,
                '                        <!-- default is mobile image -->',
                '                        <!-- // For SFCC <img alt="" src="images/content-assets/homepage/cd-hero1-m.png?$staticlink$"/>-->',
                `                        <img alt="" src="${tileImageMobile}"/>`,
                '                   </picture>',
                '                </div>',
                `                <h3 class="link-arrow">${tilesButton}</h3>`,
                '            </a>',
            ];
            $.merge(htmlBuild, tilesOutside);
        }

        /// Close the tile container
        $.merge(htmlBuild, tilesContainerEnd);
    }
    /// Close the tiles
    $.merge(htmlBuild, tiles2);

    html = $(htmlBuild.join('\n'));

    $('.built-content').append(html);
}

function buildThirdSplit(contentParams) {
    const cHeaderStyle = getHeaderStyle(contentParams.headerStyle);
    const cSubHeaderStyle = getSubHeaderStyle(contentParams.subHeaderStyle);
    const cCtaStyle = getCtaStyle(contentParams.ctaStyle);
    let cBannerAlternate = '';
    if (contentParams.bannerAlternate) {
        cBannerAlternate = 'alternate';
    }
    const cSideBySidePosition = getSideBySidePos(contentParams.sideBySidePosition);
    let cPos1 = '';
    if (cSideBySidePosition === 'first') {
        cPos1 = 'second';
    } else {
        cPos1 = 'first';
    }

    $('.built-content').empty();

    let htmlButtons = '';
    for (let i = 0; i < contentParams.ctaCount; i += 1) {
        if (i === 0) {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[0]}</a>`;
        } else if (i === 1) {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[1]}</a>`;
        } else {
            htmlButtons += `<a href="\${#}" class="button ${cCtaStyle}">${contentParams.button[2]}</a>`;
        }
    }

    const html = $([
        '<!-- Home banner slot side by side -->',
        `<div class="hero-container banner third-split ${cBannerAlternate}">`,
        '    <picture>',
        '        <!-- desktop image -->',
        '        <!-- //For SFCC <source media="(min-width: 1025px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
        `        <source media="(min-width: 1025px)" srcset="${thirdSplit}">`,
        '        <!-- tablet image -->',
        '        <!-- // For SFCC <source media="(min-width: 768px)" srcset="images/content-assets/homepage/cd-hero1.png?$staticlink$">-->',
        `        <source media="(min-width: 768px)" srcset="${thirdSplitMobile}">`,
        '        <!-- default is mobile image -->',
        '        <!-- // For SFCC <img alt="" src="images/content-assets/homepage/cd-hero1-m.png?$staticlink$"/>-->',
        `        <img alt="" src="${thirdSplitMobile}"/>`,
        '    </picture>',
        '    <div class="hero-content">',
        `        <div class="hero-content-inner split-item flex ${cSideBySidePosition}">`,
        '            <div class="container">',
        `                <img alt="" src="${thirdSplitCard}"/>`,
        '            </div>',
        '            <div class="container">',
        `                <span class="type-hero ${cHeaderStyle}">${contentParams.header[0]}</span>`,
        `                <p class="${cSubHeaderStyle}">${contentParams.subHeader}</p>`,
        '            </div>',
        '        </div>',
        `        <div class="hero-content-inner split-item interior-button ${cPos1}">`,
        '            <div class="cta-container">',
        `                ${htmlButtons}`,
        '            </div>',
        '        </div>',
        '    </div',
        '</div>',
    ].join('\n'));

    $('.built-content').append(html);
}

function initializeEvents() {
    $('[id*="_banneralternate"]').parents('.checkbox').hide();
    $('[id*="_bannersidebyside"]').parents('.checkbox').hide();
    $('[id*="_bannerstacked"]').parents('.checkbox').hide();
    $('[id*="_sidebysideposition"]').parents('.selectbox').hide();
    $('[id*="_tilecontentlocation"]').parents('.selectbox').hide();
    $('[id*="_tilealternate"]').parents('.checkbox').hide();
    $('[id*="_tiletreatment"]').parents('.checkbox').hide();
    $('[id*="_secondheading"]').parents('.form-row').hide();
    $('[id*="_thirdheading"]').parents('.form-row').hide();
    $('[id*="_secondbuttontext"]').parents('.form-row').hide();
    $('[id*="_thirdbuttontext"]').parents('.form-row').hide();
    $('[id*="_tilessubcallout"]').parents('.form-row').hide();
    $('[id*="_tilescallout"]').parents('.form-row').hide();
    $('.tilesheaderone').hide();
    $('.tilesheadertwo').hide();
    $('[id*="_t2firstbuttontext"]').parents('.form-row').hide();
    $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
    $('[id*="_t3firstbuttontext"]').parents('.form-row').hide();
    $('[id*="_t3secondbuttontext"]').parents('.form-row').hide();

    $('code').click((event) => {
        $(event.currentTarget).select();

        const doc = document;
        let range;
        let selection;

        if (doc.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(event.currentTarget);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(event.currentTarget);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    });

    $('body').on('click', '#selectallcode', () => {
        $('code').trigger('click');
        document.execCommand('copy');
    });

    $('body').on('click', '#generatecontent', () => {
        const contentParams = {
            type: $('[id*="_contenttype"]').val(),
            width: $('[id*="_contentwidth"]').val(),
            position: $('[id*="_contentposition"]').val(),
            background: $('[id*="_contentbackground"]').is(':checked'),
            headerStyle: $('[id*="_headerstyle"]').val(),
            subHeaderStyle: $('[id*="_subheaderstyle"]').val(),
            ctaCount: $('[id*="_ctacount"]').val(),
            ctaStyle: $('[id*="_ctastyle"]').val(),
            heroSecondary: $('[id*="_herosecondary"]').is(':checked'),
            bannerAlternate: $('[id*="_banneralternate"]').is(':checked'),
            bannerSideBySide: $('[id*="_bannersidebyside"]').is(':checked'),
            sideBySidePosition: $('[id*="_sidebysideposition"]').val(),
            bannerStacked: $('[id*="_bannerstacked"]').is(':checked'),
            mobilePrimary: $('[id*="_mobileprimary"]').is(':checked'),
            header: [
                $('[id*="_firstheading"]').val() !== '' ? $('[id*="_firstheading"]').val() : 'Some Cool<br> Words Here',
                $('[id*="_secondheading"]').val() !== '' ? $('[id*="_secondheading"]').val() : 'Some Cool<br> Words Here',
                $('[id*="_thirdheading"]').val() !== '' ? $('[id*="_thirdheading"]').val() : 'Some Cool<br> Words Here',
            ],
            subHeader: $('[id*="_firstsubheading"]').val() !== '' ? $('[id*="_firstsubheading"]').val() : 'Some interesting and exciting text.',
            button: [
                $('[id*="_firstbuttontext"]').val() !== '' ? $('[id*="_firstbuttontext"]').val() : 'Learn More',
                $('[id*="_secondbuttontext"]').val() !== '' ? $('[id*="_secondbuttontext"]').val() : 'Learn More',
                $('[id*="_thirdbuttontext"]').val() !== '' ? $('[id*="_thirdbuttontext"]').val() : 'Learn More',
            ],
            tile: {
                type: $('[id*="_contenttype"]').val(),
                contentLocation: $('[id*="_tilecontentlocation"]').val(),
                alternate: $('[id*="_tilealternate"]').is(':checked'),
                treatment: $('[id*="_tiletreatment"]').is(':checked'),
                calloutHeader: $('[id*="_tilescallout"]').val() !== '' ? $('[id*="_tilescallout"]').val() : 'Some Stuff Here.',
                calloutSubHeader: $('[id*="_tilessubcallout"]').val() !== '' ? $('[id*="_tilessubcallout"]').val() : 'More interesting things about this content goes right here.',
                twoButton: [
                    $('[id*="_t2firstbuttontext"]').val() !== '' ? $('[id*="_t2firstbuttontext"]').val() : 'Learn More',
                    $('[id*="_t2secondbuttontext"]').val() !== '' ? $('[id*="_t2secondbuttontext"]').val() : 'Learn More',
                ],
                threeButton: [
                    $('[id*="_t3firstbuttontext"]').val() !== '' ? $('[id*="_t3firstbuttontext"]').val() : 'Learn More',
                    $('[id*="_t3secondbuttontext"]').val() !== '' ? $('[id*="_t3secondbuttontext"]').val() : 'Learn More',
                ],
            },
        };

        switch (contentParams.type) {
        case '1':
            buildHero(contentParams);
            break;
        case '2':
            buildBanner(contentParams);
            break;
        case '3':
            buildTiles(contentParams);
            break;
        case '4':
            buildTiles(contentParams);
            break;
        case '5':
            buildThirdSplit(contentParams);
            break;
        default:
            break;
        }

        showCode();
    });

    $('[id*="_contenttype"]').change((event) => {
        switch ($(event.currentTarget).val()) {
        case '1':
            $('[id*="_herosecondary"]').parents('.checkbox').show();
            $('[id*="_contentbackground"]').parents('.checkbox').show();
            $('[id*="_contentwidth"]').parents('.selectbox').show();
            $('[id*="_contentposition"]').parents('.selectbox').show();
            $('[id*="_headerstyle"]').parents('.selectbox').show();
            $('[id*="_mobileprimary"]').parents('.checkbox').show();
            $('[id*="_subheaderstyle"]').parents('.selectbox').show();
            $('[id*="_ctacount"]').parents('.selectbox').show();
            $('[id*="_ctastyle"]').parents('.selectbox').show();
            $('[id*="_firstheading"]').parents('.form-row').show();
            $('[id*="_firstbuttontext"]').parents('.form-row').show();
            $('[id*="_firstsubheading"]').parents('.form-row').show();

            $('[id*="_banneralternate"]').parents('.checkbox').hide();
            $('[id*="_bannersidebyside"]').parents('.checkbox').hide();
            $('[id*="_sidebysideposition"]').parents('.selectbox').hide();
            $('[id*="_tilealternate"]').parents('.checkbox').hide();
            $('[id*="_tiletreatment"]').parents('.checkbox').hide();
            $('[id*="_tilecontentlocation"]').parents('.selectbox').hide();
            $('[id*="_secondheading"]').parents('.form-row').hide();
            $('[id*="_thirdheading"]').parents('.form-row').hide();
            $('[id*="_secondbuttontext"]').parents('.form-row').hide();
            $('[id*="_thirdbuttontext"]').parents('.form-row').hide();
            $('[id*="_tilessubcallout"]').parents('.form-row').hide();
            $('[id*="_tilescallout"]').parents('.form-row').hide();
            $('.tilesheaderone').hide();
            $('.tilesheadertwo').hide();
            $('[id*="_t2firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3secondbuttontext"]').parents('.form-row').hide();

            if ($('[id*="_ctacount"]').val() === '1') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
            } else {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
            }
            break;
        case '2':
            $('[id*="_banneralternate"]').parents('.checkbox').show();
            $('[id*="_bannersidebyside"]').parents('.checkbox').show();
            $('[id*="_contentwidth"]').parents('.selectbox').show();
            $('[id*="_contentposition"]').parents('.selectbox').show();
            $('[id*="_headerstyle"]').parents('.selectbox').show();
            $('[id*="_mobileprimary"]').parents('.checkbox').show();
            $('[id*="_subheaderstyle"]').parents('.selectbox').show();
            $('[id*="_ctacount"]').parents('.selectbox').show();
            $('[id*="_ctastyle"]').parents('.selectbox').show();
            $('[id*="_firstheading"]').parents('.form-row').show();
            $('[id*="_firstbuttontext"]').parents('.form-row').show();
            $('[id*="_firstsubheading"]').parents('.form-row').show();

            $('[id*="_herosecondary"]').parents('.checkbox').hide();
            $('[id*="_contentbackground"]').parents('.checkbox').hide();
            $('[id*="_tilealternate"]').parents('.checkbox').hide();
            $('[id*="_tilecontentlocation"]').parents('.selectbox').hide();
            $('[id*="_tiletreatment"]').parents('.checkbox').hide();
            $('[id*="_secondheading"]').parents('.form-row').hide();
            $('[id*="_thirdheading"]').parents('.form-row').hide();
            $('[id*="_secondbuttontext"]').parents('.form-row').hide();
            $('[id*="_thirdbuttontext"]').parents('.form-row').hide();
            $('[id*="_tilessubcallout"]').parents('.form-row').hide();
            $('[id*="_tilescallout"]').parents('.form-row').hide();
            $('.tilesheaderone').hide();
            $('.tilesheadertwo').hide();
            $('[id*="_t2firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3secondbuttontext"]').parents('.form-row').hide();

            if ($('[id*="_bannersidebyside"]').is(':checked')) {
                $('[id*="_sidebysideposition"]').parents('.selectbox').show();
                $('[id*="_bannerstacked"]').parents('.checkbox').show();
                if (!$('[id*="_bannerstacked"]').is(':checked')) {
                    $('[id*="_contentwidth"]').parents('.selectbox').hide();
                    $('[id*="_contentposition"]').parents('.selectbox').hide();
                }
            } else {
                $('[id*="_sidebysideposition"]').parents('.selectbox').hide();
                $('[id*="_contentwidth"]').parents('.selectbox').show();
                $('[id*="_contentposition"]').parents('.selectbox').show();
                $('[id*="_bannerstacked"]').parents('.checkbox').hide();
            }
            if ($('[id*="_ctacount"]').val() === '1') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
            } else {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
            }
            break;
        case '3':
            $('[id*="_tilealternate"]').parents('.checkbox').show();
            $('[id*="_tiletreatment"]').parents('.checkbox').show();
            $('[id*="_tilecontentlocation"]').parents('.selectbox').show();
            $('[id*="_contentposition"]').parents('.selectbox').show();
            $('[id*="_headerstyle"]').parents('.selectbox').show();
            $('[id*="_mobileprimary"]').parents('.checkbox').show();
            $('[id*="_ctacount"]').parents('.selectbox').show();
            $('[id*="_ctastyle"]').parents('.selectbox').show();
            $('[id*="_tilessubcallout"]').parents('.form-row').show();
            $('[id*="_tilescallout"]').parents('.form-row').show();
            $('[id*="_secondheading"]').parents('.form-row').show();
            $('.tilesheaderone').show();
            $('[id*="_t2firstbuttontext"]').parents('.form-row').show();

            $('[id*="_subheaderstyle"]').parents('.selectbox').hide();
            $('[id*="_contentwidth"]').parents('.selectbox').hide();
            $('[id*="_herosecondary"]').parents('.checkbox').hide();
            $('[id*="_contentbackground"]').parents('.checkbox').hide();
            $('[id*="_banneralternate"]').parents('.checkbox').hide();
            $('[id*="_bannersidebyside"]').parents('.checkbox').hide();
            $('[id*="_sidebysideposition"]').parents('.selectbox').hide();
            $('[id*="_bannerstacked"]').parents('.checkbox').hide();
            $('[id*="_firstsubheading"]').parents('.form-row').hide();
            $('[id*="_thirdbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3secondbuttontext"]').parents('.form-row').hide();
            $('.tilesheadertwo').hide();

            if ($('[id*="_ctacount"]').val() === '1') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
                $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
            } else {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
                $('[id*="_t2secondbuttontext"]').parents('.form-row').show();
            }
            break;
        case '4':
            $('[id*="_tilealternate"]').parents('.checkbox').show();
            $('[id*="_tiletreatment"]').parents('.checkbox').show();
            $('[id*="_tilecontentlocation"]').parents('.selectbox').show();
            $('[id*="_contentposition"]').parents('.selectbox').show();
            $('[id*="_headerstyle"]').parents('.selectbox').show();
            $('[id*="_mobileprimary"]').parents('.checkbox').show();
            $('[id*="_ctacount"]').parents('.selectbox').show();
            $('[id*="_ctastyle"]').parents('.selectbox').show();
            $('[id*="_tilessubcallout"]').parents('.form-row').show();
            $('[id*="_tilescallout"]').parents('.form-row').show();
            $('[id*="_secondheading"]').parents('.form-row').show();
            $('[id*="_thirdheading"]').parents('.form-row').show();
            $('.tilesheaderone').show();
            $('.tilesheadertwo').show();
            $('[id*="_t2firstbuttontext"]').parents('.form-row').show();
            $('[id*="_t3firstbuttontext"]').parents('.form-row').show();

            $('[id*="_subheaderstyle"]').parents('.selectbox').hide();
            $('[id*="_contentwidth"]').parents('.selectbox').hide();
            $('[id*="_herosecondary"]').parents('.checkbox').hide();
            $('[id*="_contentbackground"]').parents('.checkbox').hide();
            $('[id*="_banneralternate"]').parents('.checkbox').hide();
            $('[id*="_bannersidebyside"]').parents('.checkbox').hide();
            $('[id*="_sidebysideposition"]').parents('.selectbox').hide();
            $('[id*="_bannerstacked"]').parents('.checkbox').hide();
            $('[id*="_firstsubheading"]').parents('.form-row').hide();
            $('[id*="_thirdbuttontext"]').parents('.form-row').hide();

            if ($('[id*="_ctacount"]').val() === '1') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
                $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
                $('[id*="_t3secondbuttontext"]').parents('.form-row').hide();
            } else {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
                $('[id*="_t2secondbuttontext"]').parents('.form-row').show();
                $('[id*="_t3secondbuttontext"]').parents('.form-row').show();
            }
            break;
        case '5':
            $('[id*="_sidebysideposition"]').parents('.selectbox').show();
            $('[id*="_headerstyle"]').parents('.selectbox').show();
            $('[id*="_mobileprimary"]').parents('.checkbox').show();
            $('[id*="_subheaderstyle"]').parents('.selectbox').show();
            $('[id*="_ctacount"]').parents('.selectbox').show();
            $('[id*="_ctastyle"]').parents('.selectbox').show();
            $('[id*="_secondheading"]').parents('.form-row').show();
            $('[id*="_firstheading"]').parents('.form-row').show();
            $('[id*="_firstsubheading"]').parents('.form-row').show();

            $('[id*="_banneralternate"]').parents('.checkbox').hide();
            $('[id*="_bannersidebyside"]').parents('.checkbox').hide();
            $('[id*="_tilealternate"]').parents('.checkbox').hide();
            $('[id*="_tiletreatment"]').parents('.checkbox').hide();
            $('[id*="_herosecondary"]').parents('.checkbox').hide();
            $('[id*="_contentwidth"]').parents('.selectbox').hide();
            $('[id*="_contentbackground"]').parents('.checkbox').hide();
            $('[id*="_contentposition"]').parents('.selectbox').hide();
            $('[id*="_tilecontentlocation"]').parents('.selectbox').hide();
            $('[id*="_bannerstacked"]').parents('.checkbox').hide();
            $('[id*="_secondheading"]').parents('.form-row').hide();
            $('[id*="_thirdheading"]').parents('.form-row').hide();
            $('[id*="_secondbuttontext"]').parents('.form-row').hide();
            $('[id*="_thirdbuttontext"]').parents('.form-row').hide();
            $('[id*="_tilessubcallout"]').parents('.form-row').hide();
            $('[id*="_tilescallout"]').parents('.form-row').hide();
            $('[id*="_mobileprimary"]').parents('.checkbox').hide();
            $('.tilesheaderone').hide();
            $('.tilesheadertwo').hide();
            $('[id*="_t2firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3secondbuttontext"]').parents('.form-row').hide();
            break;
        default:
            break;
        }
    });
    $('[id*="_bannersidebyside"]').change(() => {
        if ($('[id*="_bannersidebyside"]').is(':checked')) {
            $('[id*="_sidebysideposition"]').parents('.selectbox').show();
            $('[id*="_bannerstacked"]').parents('.checkbox').show();
            if (!$('[id*="_bannerstacked"]').is(':checked')) {
                $('[id*="_contentwidth"]').parents('.selectbox').hide();
                $('[id*="_contentposition"]').parents('.selectbox').hide();
            }
        } else {
            $('[id*="_sidebysideposition"]').parents('.selectbox').hide();
            $('[id*="_contentwidth"]').parents('.selectbox').show();
            $('[id*="_contentposition"]').parents('.selectbox').show();
            $('[id*="_bannerstacked"]').parents('.checkbox').hide();
            $('[id*="_bannerstacked"]').prop('checked', false);
        }
    });
    $('[id*="_bannerstacked"]').change(() => {
        if ($('[id*="_bannerstacked"]').is(':checked')) {
            $('[id*="_contentwidth"]').parents('.selectbox').show();
            $('[id*="_contentposition"]').parents('.selectbox').show();
        } else {
            $('[id*="_contentwidth"]').parents('.selectbox').hide();
            $('[id*="_contentposition"]').parents('.selectbox').hide();
        }
    });
    $('[id*="_tilecontentlocation"]').on('change', (event) => {
        if ($(event.currentTarget).val() === '2') {
            $('[id*="_contentposition"]').parents('.selectbox').hide();
            $('[id*="_ctacount"]').parents('.selectbox').hide();
            $('[id*="_ctastyle"]').parents('.selectbox').hide();
            $('.tilesheaderone').hide();
            $('.tilesheadertwo').hide();
            $('[id*="_t2firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3firstbuttontext"]').parents('.form-row').hide();
            $('[id*="_t3secondbuttontext"]').parents('.form-row').hide();

            if ($('[id*="_contenttype"]').val() === '3') {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
                $('[id*="_firstheading"]').parents('.form-row').hide();
                $('[id*="_secondheading"]').parents('.form-row').hide();
                $('[id*="_thirdheading"]').parents('.form-row').hide();
            } else if ($('[id*="_contenttype"]').val() === '4') {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
                $('[id*="_thirdbuttontext"]').parents('.form-row').show();
                $('[id*="_firstheading"]').parents('.form-row').hide();
                $('[id*="_secondheading"]').parents('.form-row').hide();
                $('[id*="_thirdheading"]').parents('.form-row').hide();
            }
        } else {
            $('[id*="_contentposition"]').parents('.selectbox').show();
            $('[id*="_ctacount"]').parents('.selectbox').show();
            $('[id*="_ctastyle"]').parents('.selectbox').show();

            if ($('[id*="_contenttype"]').val() === '3') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
                $('[id*="_firstheading"]').parents('.form-row').show();
                $('[id*="_secondheading"]').parents('.form-row').show();
                $('.tilesheaderone').show();
                $('[id*="_t2firstbuttontext"]').parents('.form-row').show();
            } else if ($('[id*="_contenttype"]').val() === '4') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
                $('[id*="_thirdbuttontext"]').parents('.form-row').hide();
                $('[id*="_firstheading"]').parents('.form-row').show();
                $('[id*="_secondheading"]').parents('.form-row').show();
                $('[id*="_thirdheading"]').parents('.form-row').show();
                $('.tilesheaderone').show();
                $('.tilesheadertwo').show();
                $('[id*="_t2firstbuttontext"]').parents('.form-row').show();
                $('[id*="_t3firstbuttontext"]').parents('.form-row').show();
            }

            if ($('[id*="_ctacount"]').val() === '1') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
            } else {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
            }
        }
    });
    $('[id*="_ctacount"]').change((event) => {
        if ($(event.currentTarget).val() === '1') {
            $('[id*="_secondbuttontext"]').parents('.form-row').hide();
        } else {
            $('[id*="_secondbuttontext"]').parents('.form-row').show();
        }
        if ($('[id*="_contenttype"]').val() === '3') {
            if ($('[id*="_ctacount"]').val() === '1') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
                $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
            } else {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
                $('[id*="_t2secondbuttontext"]').parents('.form-row').show();
            }
        }
        if ($('[id*="_contenttype"]').val() === '4') {
            if ($('[id*="_ctacount"]').val() === '1') {
                $('[id*="_secondbuttontext"]').parents('.form-row').hide();
                $('[id*="_t2secondbuttontext"]').parents('.form-row').hide();
                $('[id*="_t3secondbuttontext"]').parents('.form-row').hide();
            } else {
                $('[id*="_secondbuttontext"]').parents('.form-row').show();
                $('[id*="_t2secondbuttontext"]').parents('.form-row').show();
                $('[id*="_t3secondbuttontext"]').parents('.form-row').show();
            }
        }
    });
}

module.exports = {
    init() {
        initializeEvents();
    },
};
